import { LecturesVideoInfosType } from '../type';
import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import { Text } from '../../../../common/text/TextStyle';
import { FiEdit } from 'react-icons/fi';
import { BsTrash } from 'react-icons/bs';
import { ColorTheme } from '../../../../theme/color';

type VideoLinkListProps = {
  videoList: LecturesVideoInfosType[];
  handleEditSelectedLecture: (selectedLecture: LecturesVideoInfosType) => void;
  handleDeleteSelectedLecture: (selectedLecture: LecturesVideoInfosType) => void;
};
const VideoLinkList = ({
  videoList,
  handleDeleteSelectedLecture,
  handleEditSelectedLecture,
}: VideoLinkListProps) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box display={'flex'} gap={'20px'} flexWrap={'wrap'}>
          {videoList.map((video) => (
            <Box bgcolor={'#efefef'} p={'20px'} borderRadius={'20px'} position={'relative'}>
              <Box pt={'35px'}>
                <Box width={'200px'}>
                  <LiteYouTubeEmbed
                    containerElement='animate'
                    id={video.videoLink}
                    title={video.title}
                  />
                </Box>
                <Tooltip title={video.description}>
                  <Text textAlign={'center'} size='md'>
                    {video.title}
                  </Text>
                </Tooltip>
              </Box>
              <Box
                position={'absolute'}
                right={10}
                top={10}
                display={'flex'}
                justifyContent={'flex-end'}
              >
                <IconButton onClick={() => handleEditSelectedLecture(video)}>
                  <FiEdit color={ColorTheme.base.primary} size={'20px'} />
                </IconButton>
                <IconButton onClick={() => handleDeleteSelectedLecture(video)}>
                  <BsTrash color={ColorTheme.system.error[400]} size={'20px'} />
                </IconButton>
              </Box>
            </Box>
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};

export default VideoLinkList;
