import { initializeApp } from 'firebase/app';
import { getFirestore, collection } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
import firebaseConfig from './config';

// init firebase app
const app = initializeApp(firebaseConfig);
// init services
export const db = getFirestore(app);
export const firebaseAuth = getAuth(app);
export const analytics = typeof window !== 'undefined' ? getAnalytics(app) : null;

// collection ref
export const usersCollectionRef = collection(db, 'users');
export const pdfCollectionRef = collection(db, 'pdfList');
export const QuestionListCollectionRef = collection(db, 'questionList');
export const postStudentQuestionCollectionRef = collection(db, 'postStudentQuestion');
export const lectureCollectionRef = collection(db, 'lectures');
