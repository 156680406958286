import { Grid } from '@mui/material';
import { ReactNode } from 'react';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import { pathIncludesNavbarStyling } from '../../util/SiteUtil';
import StyledMarquee from '../homescreen/component/StyledMarquee';

type PublicRouteProps = {
  children: ReactNode;
};
const PublicRoute = ({ children }: PublicRouteProps) => {
  const path = window.location.pathname;
  return (
    <Grid container bgcolor={'#f5f4f8'}>
      <Grid
        item
        xs={12}
        zIndex={99}
        position={'fixed'}
        top={'0px'}
        width={'100%'}
        bgcolor={'#ed3f24'}
      >
        <StyledMarquee
          bannerTexts={[
            {
              id: 'jee_2025',
              linkText: 'CLICK HERE TO GET JEE 2025 UPDATE',
              details: 'JEE 2025 Update',
            },
            {
              id: 'jee_2024',
              linkText: 'CLICK HERE TO GET JEE ADVANCE PAPER',
              details: 'JEE advanced 2024 paper and solution available 🎉🎉🎉',
            },
          ]}
        />
      </Grid>
      <Grid item xs={12}>
        <Header />
      </Grid>
      <Grid
        item
        xs={12}
        minHeight={'100vh'}
        mt={pathIncludesNavbarStyling(path) ? ['20px', '20px', '150px'] : '0px'}
      >
        {children}
      </Grid>
      <Grid item xs={12}>
        <Footer />
      </Grid>
    </Grid>
  );
};

export default PublicRoute;
