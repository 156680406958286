import { MdOutlineDashboardCustomize } from 'react-icons/md';
import { SideBarOptionType } from './type';
import { routeUrl } from '../route/url';
import { BsFilePdf } from 'react-icons/bs';
import { SiAnswer } from 'react-icons/si';
import { CiVideoOn } from 'react-icons/ci';

export const sideMenuOptions: SideBarOptionType[] = [
  {
    id: 1,
    label: 'Question',
    toShow: true,
    icon: <MdOutlineDashboardCustomize />,
    url: routeUrl.dashboard,
  },
  {
    id: 2,
    label: 'PDF Section',
    toShow: true,
    icon: <BsFilePdf />,
    url: routeUrl.pdfs,
  },
  {
    id: 2,
    label: 'Lectures Section',
    toShow: true,
    icon: <CiVideoOn />,
    url: routeUrl.lectures,
  },
  {
    id: 3,
    label: 'Ask Doubt',
    toShow: true,
    icon: <SiAnswer />,
    url: routeUrl.askDoubtAdmin,
  },
];
