import { object, string } from 'yup';
import { LecturesVideoInfosType, LectureType } from './type';
import { generateUniqueId } from '../../../util/SiteUtil';

export const initialAddLectureValue = (selectedLecture: LectureType | null) => ({
  name: selectedLecture?.name || '',
  description: selectedLecture?.description || '',
});

export const addUpdateLectureValidationSchema = object().shape({
  name: string().required('Please enter name'),
  description: string(),
});

export const lectureUrlInitialValue = (data?: LecturesVideoInfosType) => ({
  description: data?.description || '',
  title: data?.title || '',
  videoLink: data?.videoLink || '',
  id: data?.id || generateUniqueId(),
});

export const lectureUrlValidationSchema = object().shape({
  description: string(),
  title: string().required('Please enter question'),
  videoLink: string().required('Please enter public Video Link'),
  id: string().required(),
});
