import React from 'react';
import { Control, useWatch } from 'react-hook-form';
import { LecturesVideoInfosType } from '../type';
import { Grid } from '@mui/material';
import { HeadingText, Text } from '../../../../common/text/TextStyle';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';

type PreviewSecionProps = {
  control: Control<LecturesVideoInfosType>;
};
const VideoPreviewSection = ({ control }: PreviewSecionProps) => {
  const title = useWatch({
    control,
    name: 'title',
  });
  const videoLink = useWatch({
    control,
    name: 'videoLink',
  });
  const description = useWatch({
    control,
    name: 'description',
  });
  return (
    <Grid container mt={'10px'}>
      <Grid item xs={12} p={'10px'} border={`1px #efefef solid`} borderRadius={'10px'}>
        <HeadingText size='sm' textweight='bold'>
          {title}
        </HeadingText>
        <Text size='md'>{description}</Text>
      </Grid>
      {videoLink && (
        <Grid item xs={12} mt={'40px'}>
          <LiteYouTubeEmbed id={videoLink} title={title} />
        </Grid>
      )}
    </Grid>
  );
};

export default VideoPreviewSection;
