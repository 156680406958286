/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { LectureInfoType, LecturesVideoInfosType, LectureType } from './type';
import { useForm } from 'react-hook-form';
import {
  addUpdateLectureValidationSchema,
  initialAddLectureValue,
  lectureUrlInitialValue,
  lectureUrlValidationSchema,
} from './util';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  addLectureFolder,
  addNewLectureVideoDetail,
  getLectureDetail,
  getLectureFolderList,
  handleDeleteLecture,
  handleDeleteParticularLecture,
  handleUpdateParticularLecture,
  updateLectureFolderInfo,
} from '../../../database/datahelper';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';

export const useLectureSection = () => {
  const [selectedLecture, setSelectedLecture] = useState<LectureType | null>(null);
  const [addLectureModal, setAddLectureModal] = useState(false);
  const [lectureList, setLectureList] = useState<LectureType[]>([]);
  const [deleteLecture, setDeleteLecture] = useState<LectureType>();
  const [deleteLoading, setDeleteLoading] = useState(false);

  useEffect(() => {
    fetchLecture();
  }, []);

  const fetchLecture = () => {
    getLectureFolderList().then((res) => {
      setLectureList(res);
    });
  };

  const handleDelete = () => {
    if (!deleteLecture) {
      return;
    }
    setDeleteLoading(true);
    handleDeleteLecture(deleteLecture.id)
      .then(() => {
        setDeleteLecture(undefined);
        fetchLecture();
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => {
        setDeleteLoading(false);
      });
  };

  const handleRefresh = () => {
    fetchLecture();
    setSelectedLecture(null);
    setAddLectureModal(false);
  };
  return {
    selectedLecture,
    setSelectedLecture,
    addLectureModal,
    setAddLectureModal,
    handleRefresh,
    lectureList,
    deleteLecture,
    handleDelete,
    deleteLoading,
    setDeleteLecture,
  };
};

export const useAddUpdateLecture = (
  selectedLecture: LectureType | null,
  handleRefresh: () => void,
) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: initialAddLectureValue(selectedLecture),
    resolver: yupResolver(addUpdateLectureValidationSchema),
  });

  const handleAddUpdate = (value: LectureInfoType) => {
    setIsLoading(true);
    if (selectedLecture) {
      updateLectureFolderInfo({
        description: value.description || '',
        name: value.name,
        id: selectedLecture.id,
      })
        .then(() => {
          handleRefresh();
        })
        .catch((err) => {
          toast.error(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
      return;
    }
    addLectureFolder(value)
      .then(() => {
        handleRefresh();
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    handleSubmit,
    register,
    errors,
    isLoading,
    handleAddUpdate,
  };
};

export const useLectureInfoDetail = () => {
  const [lectureDetails, setLectureDetail] = useState<LectureType | null>(null);
  const { lectureId } = useParams();
  const [addNewLecture, setAddNewLecture] = useState(false);
  const [selectedLecture, setSelectedLecture] = useState<LecturesVideoInfosType>();
  const [deleteLecture, setDeleteLecture] = useState<LecturesVideoInfosType>();
  const [deleteLoading, setDeleteLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    fetchLectureDetail();
  }, []);

  const handleDelete = () => {
    if (!deleteLecture || !lectureId) {
      return;
    }
    setDeleteLoading(true);
    handleDeleteParticularLecture(lectureId, deleteLecture)
      .then(() => {
        setDeleteLecture(undefined);
        fetchLectureDetail();
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => {
        setDeleteLoading(false);
      });
  };

  const fetchLectureDetail = () => {
    lectureId &&
      getLectureDetail(lectureId).then((res) => {
        setLectureDetail(res);
        setAddNewLecture(false);
        setSelectedLecture(undefined);
      });
  };

  return {
    lectureDetails,
    navigate,
    addNewLecture,
    setAddNewLecture,
    refresh: fetchLectureDetail,
    setSelectedLecture,
    selectedLecture,
    handleDelete,
    deleteLoading,
    setDeleteLecture,
    deleteLecture,
  };
};

export const useLectureVideoDetailDrawer = (
  selectedLectureInfo?: LecturesVideoInfosType,
  refreshList?: () => void,
) => {
  const [selectedTab, setSelectedTab] = useState('form');
  const [loading, setLoading] = useState(false);
  const { lectureId } = useParams();
  const {
    handleSubmit,
    register,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: lectureUrlInitialValue(selectedLectureInfo),
    resolver: yupResolver(lectureUrlValidationSchema) as any,
  });

  const onSubmit = (values: LecturesVideoInfosType) => {
    setLoading(true);
    if (selectedLectureInfo && lectureId) {
      handleUpdateParticularLecture(lectureId, values).then(() => {
        refreshList && refreshList();
        setLoading(false);
      });
      return;
    }
    lectureId &&
      addNewLectureVideoDetail(lectureId, values).then(() => {
        refreshList && refreshList();
        setLoading(false);
      });
  };

  return {
    handleSubmit,
    register,
    control,
    setValue,
    reset,
    errors,
    selectedTab,
    setSelectedTab,
    loading,
    onSubmit,
  };
};
