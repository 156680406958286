import { LectureType } from '../type';
import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import { FaFolder } from 'react-icons/fa';
import { Text } from '../../../../common/text/TextStyle';
import { FiEdit } from 'react-icons/fi';
import { BsTrash } from 'react-icons/bs';
import { ColorTheme } from '../../../../theme/color';
import { useNavigate } from 'react-router-dom';

type LectureListProps = {
  lecturesList: LectureType[];
  handleEditSelectedLecture: (selectedLecture: LectureType) => void;
  handleDeleteSelectedLecture: (selectedLecture: LectureType) => void;
};

const LectureList = ({
  lecturesList,
  handleEditSelectedLecture,
  handleDeleteSelectedLecture,
}: LectureListProps) => {
  const navigate = useNavigate();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box display={'flex'} gap={'20px'} flexWrap={'wrap'}>
          {lecturesList.map((lec) => (
            <Box border={'1px #ccc solid'} p={'10px'} borderRadius={'10px'} position={'relative'}>
              <Box pt={'13px'}>
                <IconButton onClick={() => navigate(`/admin/lectures/${lec.id}`)}>
                  <FaFolder color='#f7f17e' size={'80px'} />
                </IconButton>
                <Tooltip title={lec.description}>
                  <Text textAlign={'center'} size='md'>
                    {lec.name}
                  </Text>
                </Tooltip>
              </Box>
              <Box
                position={'absolute'}
                right={0}
                top={0}
                display={'flex'}
                justifyContent={'flex-end'}
              >
                <IconButton onClick={() => handleEditSelectedLecture(lec)}>
                  <FiEdit color={ColorTheme.base.primary} size={'20px'} />
                </IconButton>
                <IconButton onClick={() => handleDeleteSelectedLecture(lec)}>
                  <BsTrash color={ColorTheme.system.error[400]} size={'20px'} />
                </IconButton>
              </Box>
            </Box>
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};

export default LectureList;
