import { useLectureSection } from './hook';
import { Box, Grid } from '@mui/material';
import { PrimaryButton } from '../../../common/Button';
import LectureList from './component/LectureList';
import AddUpdateLecture from './component/AddUpdateLecture';
import { Text } from '../../../common/text/TextStyle';
import DeleteConfirmation from '../../../common/DeleteConfirmation';

const LecturesSection = () => {
  const {
    addLectureModal,
    lectureList,
    handleRefresh,
    selectedLecture,
    setAddLectureModal,
    setSelectedLecture,
    deleteLecture,
    handleDelete,
    deleteLoading,
    setDeleteLecture,
  } = useLectureSection();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Box>
            <Text size='lg' textweight='bold'>
              Lecture List
            </Text>
          </Box>
          <Box width={150}>
            <PrimaryButton title='Add Lecture' onClick={() => setAddLectureModal(true)} />
          </Box>
        </Box>
      </Grid>
      <Grid
        minHeight={'70vh'}
        item
        xs={12}
        bgcolor={'#fff'}
        p={'30px'}
        borderRadius={'20px'}
        mt={'20px'}
      >
        <LectureList
          lecturesList={lectureList}
          handleDeleteSelectedLecture={setDeleteLecture}
          handleEditSelectedLecture={setSelectedLecture}
        />
      </Grid>
      {(addLectureModal || selectedLecture) && (
        <AddUpdateLecture
          handleClose={() => {
            setAddLectureModal(false);
            setSelectedLecture(null);
          }}
          handleRefresh={handleRefresh}
          selectedLecture={selectedLecture}
        />
      )}
      {deleteLecture && (
        <DeleteConfirmation
          isLoading={deleteLoading}
          isOpen
          description='Are you sure you want to delete?'
          modalTitle='Lecture Delete'
          handleClose={() => setDeleteLecture(undefined)}
          handleSubmit={() => handleDelete()}
        />
      )}
    </Grid>
  );
};

export default LecturesSection;
