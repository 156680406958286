import { LecturesVideoInfosType } from '../type';
import { Drawer, Grid } from '@mui/material';
import { Text } from '../../../../common/text/TextStyle';
import CustomTab from '../../../../common/component/CustomTab';
import { QuestionTabOption } from '../../../dashboard/util';
import { useLectureVideoDetailDrawer } from '../hook';
import VideoPreviewSection from './VideoPreviewSection';
import LectureVideoForm from './LectureVideoForm';

type AddNewLectureVideoProps = {
  selectedLectureVideo?: LecturesVideoInfosType;
  handleClose: () => void;
  handleRefresh: () => void;
};

const AddNewLectureVideo = ({
  handleClose,
  handleRefresh,
  selectedLectureVideo,
}: AddNewLectureVideoProps) => {
  const {
    control,
    errors,
    handleSubmit,
    loading,
    onSubmit,
    register,
    selectedTab,
    setSelectedTab,
  } = useLectureVideoDetailDrawer(selectedLectureVideo, handleRefresh);

  const renderView = () => {
    if (selectedTab === 'form') {
      return (
        <LectureVideoForm
          onSubmit={handleSubmit(onSubmit)}
          loading={loading}
          handleClose={handleClose}
          register={register as any}
          errors={errors}
        />
      );
    }
    return <VideoPreviewSection control={control as any} />;
  };
  return (
    <Drawer
      anchor='right'
      open
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: '40%',
        },
      }}
    >
      <Grid container p={'30px'}>
        <Grid item xs={12}>
          <Text size='xl' textweight='bold'>
            Add Lecture Video
          </Text>
        </Grid>
        <Grid item xs={12} mt={'30px'}>
          <CustomTab
            setTabSelected={setSelectedTab}
            tabOption={QuestionTabOption}
            value={selectedTab}
          />
        </Grid>
        <Grid item xs={12}>
          {renderView()}
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default AddNewLectureVideo;
