import { Box, Grid, IconButton } from '@mui/material';
import { Text } from '../../../common/text/TextStyle';
import { PrimaryButton } from '../../../common/Button';
import { FaLeftLong } from 'react-icons/fa6';
import { useLectureInfoDetail } from './hook';
import { routeUrl } from '../../route/url';
import AddNewLectureVideo from './component/AddNewLectureVideo';
import VideoLinkList from './component/VideoLinkList';
import DeleteConfirmation from '../../../common/DeleteConfirmation';

const LectureInfoDetail = () => {
  const {
    lectureDetails,
    refresh,
    selectedLecture,
    setSelectedLecture,
    navigate,
    setAddNewLecture,
    addNewLecture,
    deleteLoading,
    handleDelete,
    setDeleteLecture,
    deleteLecture,
  } = useLectureInfoDetail();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Box display={'flex'} gap={'10px'} alignItems={'center'}>
            <IconButton onClick={() => navigate(routeUrl.lectures)}>
              <FaLeftLong />
            </IconButton>
            <Text size='lg' textweight='bold'>
              {lectureDetails?.name}
            </Text>
          </Box>
          <Box width={200}>
            <PrimaryButton onClick={() => setAddNewLecture(true)} title='Add Lecture Video' />
          </Box>
        </Box>
      </Grid>
      <Grid
        minHeight={'70vh'}
        item
        xs={12}
        bgcolor={'#fff'}
        p={'30px'}
        borderRadius={'20px'}
        mt={'20px'}
      >
        <VideoLinkList
          handleDeleteSelectedLecture={setDeleteLecture}
          handleEditSelectedLecture={setSelectedLecture}
          videoList={lectureDetails?.lectureInfo || []}
        />
      </Grid>
      {(addNewLecture || selectedLecture) && (
        <AddNewLectureVideo
          selectedLectureVideo={selectedLecture}
          handleClose={() => {
            setAddNewLecture(false);
            setSelectedLecture(undefined);
          }}
          handleRefresh={refresh}
        />
      )}
      {deleteLecture && (
        <DeleteConfirmation
          isLoading={deleteLoading}
          isOpen
          description='Are you sure you want to delete?'
          modalTitle='Lecture Delete'
          handleClose={() => setDeleteLecture(undefined)}
          handleSubmit={() => handleDelete()}
        />
      )}
    </Grid>
  );
};

export default LectureInfoDetail;
