import { LectureType } from '../type';
import { Box, Drawer, Grid } from '@mui/material';
import { Text } from '../../../../common/text/TextStyle';
import { useAddUpdateLecture } from '../hook';
import CustomTextInput from '../../../../common/field/CustomTextfield';
import { PrimaryButton, PrimaryTextButton } from '../../../../common/Button';

type AddUpdateLectureProps = {
  selectedLecture: LectureType | null;
  handleClose: () => void;
  handleRefresh: () => void;
};
const AddUpdateLecture = ({
  selectedLecture,
  handleRefresh,
  handleClose,
}: AddUpdateLectureProps) => {
  const { handleSubmit, errors, register, handleAddUpdate, isLoading } = useAddUpdateLecture(
    selectedLecture,
    handleRefresh,
  );

  return (
    <Drawer
      anchor='right'
      open
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: '40%',
        },
      }}
    >
      <Grid container p={'30px'}>
        <Grid item xs={12}>
          <Text size='xl' textweight='bold'>
            {selectedLecture ? 'Update' : 'Add'} Folder
          </Text>
        </Grid>
        <Grid item xs={12} mt={'30px'}>
          <CustomTextInput
            variant='outlined'
            name='name'
            label={'Name'}
            register={register}
            isMandatory
            fullWidth
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} mt={'5px'}>
          <CustomTextInput
            variant='outlined'
            name='description'
            label={'Description'}
            register={register}
            minRows={4}
            multiline
            fullWidth
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} mt={'10px'}>
          <Box display={'flex'} justifyContent={'flex-end'}>
            <Box sx={{ width: 150 }}>
              <PrimaryTextButton title='Cancel' onClick={() => handleClose()} />
            </Box>
            <Box sx={{ width: 150 }}>
              <PrimaryButton
                onClick={handleSubmit(handleAddUpdate)}
                isLoading={isLoading}
                title='Save'
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default AddUpdateLecture;
