export const routeUrl = {
  dashboard: '/admin/dashboard',
  login: '/admin/login',
  register: '/admin/register',
  pdfs: '/admin/pdfs',
  lectures: '/admin/lectures',
  lectureDetail: '/admin/lectures/:lectureId',
  askDoubtAdmin: '/admin/askdoubt',
  forgotPassword: '/admin/forgotPassword',
  quiz: '/quiz',
  homescreen: '/',
  questionList: '/questions',
  questionInfo: '/questionInfo/:questionId',
  contactUs: '/contactus',
  pdfsList: '/pdflist',
  askDoubt: '/askdoubt',
  askDoubtQuestionsList: '/askdoubtquestions',
};
