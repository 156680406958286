import { Box, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { Text } from '../../../common/text/TextStyle';
import { _url } from '../../../util/SiteUtil';
import { RiCloseCircleFill } from 'react-icons/ri';
import { useGetWindowSize } from '../../../common/hook';

type AutomaticBannerModalProps = {
  handleClose: () => void;
};
const AutomaticBannerModal = ({ handleClose }: AutomaticBannerModalProps) => {
  const { mobileView } = useGetWindowSize();
  return (
    <Dialog maxWidth={'md'} fullWidth open={true} onClose={handleClose}>
      <DialogTitle>
        <Box alignItems={'center'} display={'flex'} justifyContent={'space-between'}>
          <Text textweight='bold' size={mobileView ? 'sm' : 'lg'}>
            JEE Main 2025 update,Optional removed
          </Text>
          <IconButton onClick={handleClose}>
            <RiCloseCircleFill />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <img src={_url('assets/jee_update_2.png')} width={'100%'} alt={'Jee Update 2025'} />
      </DialogContent>
    </Dialog>
  );
};

export default AutomaticBannerModal;
