import React from 'react';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { LecturesVideoInfosType } from '../type';
import { Box, Grid } from '@mui/material';
import { PrimaryButton, PrimaryTextButton } from '../../../../common/Button';
import CustomTextInput from '../../../../common/field/CustomTextfield';

type QuestionFormProps = {
  register: UseFormRegister<LecturesVideoInfosType>;
  loading: boolean;
  onSubmit: () => void;
  errors: FieldErrors<LecturesVideoInfosType>;
  handleClose: () => void;
};

const LectureVideoForm = ({
  handleClose,
  errors,
  loading,
  onSubmit,
  register,
}: QuestionFormProps) => {
  return (
    <Grid container mt={'5px'} rowSpacing={'20px'}>
      <Grid item xs={12}>
        <CustomTextInput
          variant='outlined'
          name='title'
          label={'Title'}
          register={register}
          isMandatory
          fullWidth
          errors={errors}
        />
      </Grid>

      <Grid item xs={12}>
        <CustomTextInput
          variant='outlined'
          name='description'
          label={'Description'}
          register={register}
          isMandatory
          multiline
          rows={4}
          fullWidth
          errors={errors}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomTextInput
          variant='outlined'
          name='videoLink'
          label={'Youtube Video Public ID'}
          infoText={'eg.https://www.youtube.com/watch?v={youtubePublicId}&t=1332s'}
          register={register}
          isMandatory
          fullWidth
          errors={errors}
        />
      </Grid>

      <Grid item xs={12}>
        <Box display={'flex'} justifyContent={'flex-end'}>
          <Box sx={{ width: 150 }}>
            <PrimaryTextButton title='Cancel' onClick={handleClose} />
          </Box>
          <Box sx={{ width: 150 }}>
            <PrimaryButton onClick={onSubmit} isLoading={loading} title='Save' />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default LectureVideoForm;
