import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import Marquee from 'react-fast-marquee';
import BannerModal from './BannerModal';
import AutomaticBannerModal from './AutomaticBannerModal';

type marqueeProps = {
  bannerTexts: {
    id: string;
    details: string;
    linkText: string;
  }[];
};

const StyledMarquee = ({ bannerTexts }: marqueeProps) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [showAutomaticModal, setShowAutomatcModal] = useState(false);

  useEffect(() => {
    setShowAutomatcModal(true);
  }, []);
  return (
    <>
      <Marquee pauseOnHover={true} gradientColor='white' gradientWidth={'1000px'}>
        {bannerTexts.map(({ details, linkText, id }) => (
          <Box display={'flex'}>
            <Typography p={'10px'} color={'#fff'} variant='body2' fontWeight={'bold'}>
              {details}{' '}
              <span
                style={{ color: '#efefef', textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => {
                  if (id === 'jee_2024') {
                    setOpenModal(true);
                  }
                  if (id === 'jee_2025') {
                    setShowAutomatcModal(true);
                  }
                }}
              >
                {linkText}
              </span>
            </Typography>
          </Box>
        ))}
      </Marquee>
      {openModal && <BannerModal handleClose={() => setOpenModal(false)} />}
      {showAutomaticModal && (
        <AutomaticBannerModal handleClose={() => setShowAutomatcModal(false)} />
      )}
    </>
  );
};

export default StyledMarquee;
